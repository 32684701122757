import React from 'react';
import Layout from '../components/layout/Layout';
import { Link } from 'gatsby';
import Button from '../components/Button';

export default () => (
  <Layout>
    <div className="container mx-auto my-12 text-center">
      <h2 className="mb-6 font-semibold prose-2xl">
        Occurio un error!
        <br />
        Si el problema persiste haga favor de
        <a href="mailto:contacto@document.com.mx"> contactarnos</a>
      </h2>
      <Link to="/">
        <Button>Regresar</Button>
      </Link>
    </div>
  </Layout>
);
